import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";
import AllCoins from "./AllCoins";

// const useAllCoins = () => {
//   const { allMongodbWheretomineCoins, allMongodbWheretomineAlgorithms } = useStaticQuery(
//     graphql`
//       query {
//         allMongodbWheretomineCoins {
//           nodes {
//             name
//             abbreviation
//             numberOfSubPools
//             slug
//             algorithm
//           }
//         },
//         allMongodbWheretomineAlgorithms {
//           nodes {
//             name
//             mongodb_id
//             slug
//           }
//         },
//       }
//     `
//   );
//   return allMongodbWheretomineCoins.nodes.map(coin => {
//     const algorithm = allMongodbWheretomineAlgorithms.nodes.find((algorithm) => algorithm.mongodb_id === coin.algorithm);

//     return {
//       name: coin.name,
//       abbreviation: coin.abbreviation,
//       slug: coin.slug,
//       numberOfSubPools: coin.numberOfSubPools,
//       algorithm: algorithm,
//     };
//   });
// };

const AllCoinsPage = ({ pageContext }) => {
  // const coins = useAllCoins();
  const { coins } = pageContext;

  return (
    <Root>
      <SEO
        title="All cryptocurrency Mining Pools & Algorithms"
        description="View the best cryptocurrency mining pools for you favorite coins like Bitcoin, Ethereum, Monero, Zcash. Use the profit calculator to see your estimated income."
      />
      <AllCoins coins={coins} />
    </Root>
  );
};

export default AllCoinsPage;
